import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { client } from './lib/apolloClient'
import { AppProvider } from './src/components/context'
import { AuthProvider } from "./src/components/hooks/useAuth"

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
       <AuthProvider>
        <AppProvider>{element}</AppProvider>
       </AuthProvider>
     </ApolloProvider>
  )
}